const validatePaidCampaign = (paid_campaign, defaultsTo = '1') => {
  let validPaidCampaign =
    typeof paid_campaign === 'undefined' ? defaultsTo : paid_campaign;
  validPaidCampaign =
    !validPaidCampaign || validPaidCampaign === '0' ? '0' : '1';

  return validPaidCampaign;
};

export default validatePaidCampaign;
