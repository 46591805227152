import styled from 'styled-components';

export const HomeHeroStyles = styled.div`
  background: url(${props => props.background}) no-repeat center;
  background-size: cover;
  padding: 0;
  position: relative;
  padding-top: 3rem;

  :before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  }

  .customers > .image > div {
      width: 268.8px !important;
  }

  ${props => props.theme.media.lg} {
    padding: 3rem 0 0;

    .customers > .image > div {
        width: 336px !important;
    }
  }

  .hero-wrap {
    padding: 0 10px;
    position: relative;

    ${props => props.theme.media.lg} {
      padding: 0 1rem;
      display: grid;
      grid-template-columns: 1fr minmax(250px, 1fr);
      max-width: 74rem;
      margin: 0 auto;
    }
  }

  .content {
    padding: 1rem 0;
    margin: 0 auto;
    text-align: center;

    ${props => props.theme.media.lg} {
      justify-content: unset;
      margin: 4rem auto auto;
    }
  }

  .title {
    font-size: 2rem;
    font-weight: 600;
    max-width: 10em;
    margin: 0 auto 0.25em;
    line-height: 1.125;

    ${props => props.theme.media.sm} {
      font-size: 50px;
    }
  }

  .sub-header {
    margin: 0;
    font-size: 0.8rem;

    h2 {
      font-size: 21px;
      font-weight: 500;
      margin: 1rem
    }

    ${props => props.theme.media.sm} {
      font-size: 1.25rem;
      h2 {
        font-size: 30px;
        margin: 1rem
      }
    }

    ${props => props.theme.media.md} {
      font-size: 0.9375rem;
    }

    ${props => props.theme.media.lg} {
      font-size: 1.25rem;
    }

    ${props => props.theme.media.xl} {
      font-size: 1.5rem;
    }

    h3 {
      font-size: 1.25em;
      font-weight: 500;
      margin: 0 0 1rem;
    }

    p {
      font-size: 20px;
      line-height: 1.5;
      font-weight: 500;
      max-width: 500px;
      margin: auto;
      ${props => props.theme.media.sm} {
        font-weight: 400;
      }
    }
  }

  .btn-wrap {
    position: absolute;
    bottom: 18rem;
    z-index: 1;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    

    ${props => props.theme.media.sm} {
      bottom: 16rem;
    }

    ${props => props.theme.media.lg} {
      bottom: auto;
      left: auto;
      position: relative;
      -webkit-transform: none;
      transform: none;
    }

    .btn-hero-cta {
      background-color: white;
      border-color: white;
      border-radius: 2rem;
      color: ${props => props.theme.textColor};
      cursor: pointer;
      display: inline-flex;
      font-size: 14px;
      font-weight: 500;
      margin-top: 1.5rem;
      padding: 0.7em 3.5em 0.7em 1.5em;
      position: relative;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
      transition: 0.2s box-shadow ease-in-out;

      ${props => props.theme.media.sm} {
        font-size: 1.5rem;
        margin-top: 1.5rem;
      }

      &:hover {
        box-shadow: 0 0 15px rgba(241, 89, 1, 0.5);
      }

      .btn-icon {
        align-items: center;
        background: ${props => props.theme.secondary};
        border-radius: 50%;
        display: grid;
        height: 48px;
        margin: 0;
        padding: 9px;
        position: absolute;
        right: -1px;
        width: 48px;

        ${props => props.theme.media.lg} {
          height: 59px;
          width: 59px;
        }
      }
    }
  }

  .customers {
    position: relative;
    max-width: 30rem;
    margin: 0 auto;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    justify-self: flex-end;

    ${props => props.theme.media.lg} {
      margin: 0;
    }

    .image {
      .image-container {
        height: 100%;
        position: relative;
        align-items: flex-end;
        display: flex;
        height: 100%;
        right: -4.5rem;

          img {
            ${props => props.theme.media.md} {
              margin-left: -1rem;
              right: -0;
            }

            ${props => props.theme.media.xl} {
              margin-left: -2rem;
              right: -0;
            }
          }
      }

      &.fade-enter {
        opacity: 0;
      }

      &.fade-exit {
        opacity: 1;
      }

      &.fade-enter-active {
        opacity: 1;
      }

      &.fade-exit-active {
        opacity: 0;
      }

      &.fade-enter-active,
      &.fade-exit-active {
        transition: opacity 500ms cubic-bezier(0.57, 0.06, 0, 1.06);
      }
    }
  }

  .model-label {
    left: 0;
    top: 20%;
    position: absolute;
    text-align: center;

    @media screen and (min-width: 500px) {
      left: 3rem;
    }

    ${props => props.theme.media.lg} {
      left: -8rem;
      top: 30%;
    }

    ${props => props.theme.media.xl} {
      left: -10rem;
      top: 30%;
    }

    p {
      color: #646464;
      margin-bottom: 0;

      &.name {
        font-size: 1rem;
        font-weight: bold;
      }

      &.location {
        font-size: 0.75rem;
        line-height: 1.4;
      }
    }
  }
`;
