import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Router from 'next/router';
import Axios from 'axios';

const Modal = styled.div`
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  .custom-modal {
    position: relative;
    z-index: 9;
    background: white;
    border-radius: 5px;
    box-shadow: 0 15px 20px 0 rgba(42, 51, 83, 0.12), 0 5px 15px rgba(0, 0, 0, 0.06);
    padding: 2rem 1rem;
    border: 1px solid ${props => props.theme.gray200};
    width: 980px;
    max-width: 100%;
    margin: 0 1rem;
    ${props => props.theme.media.sm} {
      padding: 5rem 2rem 2rem;
    }
    ${props => props.theme.media.xl} {
      width: 1140px;
      padding: 5rem 3.5rem 3rem;
      display: flex;
      flex-direction: column;
      min-height: 500px;
    }
  }
  .cm-wrap {
    ${props => props.theme.media.sm} {
      display: flex;
    }
    ${props => props.theme.media.xl} {
      margin: auto 0;
    }
    img {
      margin: 0 auto 1rem;
      display: block;
      ${props => props.theme.media.sm} {
        margin: 0 1rem 0 0;
      }
      ${props => props.theme.media.xl} {
        margin-top: -2rem;
      }
    }
  }
  .title {
    margin-bottom: 0.5em;
    ${props => props.theme.media.xl} {
      font-size: 35px;
      line-height: 1.35;
      font-weight: 400;
      margin-bottom: 0.1em;
    }
  }
  p {
    color: ${props => props.theme.gray600};
    ${props => props.theme.media.xl} {
      font-size: 20px;
    }
  }
  .close-btn {
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    font-size: 1rem;
    height: 1rem;
    width: 1rem;
    line-height: 1;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  .btn {
    display: block;
    width: 100%;
    margin: 1rem 0 0 auto;
    ${props => props.theme.media.sm} {
      margin: 3rem 0 0 auto;
      width: auto;
    }
    ${props => props.theme.media.xl} {
      margin-top: auto;
    }
  }
`;

const ConsultationFeeModalComponent = ({ close }) => {
  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('modal-open');

    return function cleanup() {
      body.classList.remove('modal-open');
    };
  }, []);

  const addToOrderLines = async () => {
    // Specialist Consultation product
    const { data: orderLines } = await Axios.get('/api/backend/nwapi/products/consultation');

    sessionStorage.setItem('OrderLines', JSON.stringify(orderLines));
  };

  const continueToCheckout = async () => {
    await addToOrderLines();
    Router.push('/v2/checkout').then(() => window.scrollTo(0, 0));
  };

  return (
    <Modal>
      <div className="custom-modal">
        <button type="button" className="close-btn" onClick={close}>
          <FontAwesomeIcon icon={faTimes} color="#333"></FontAwesomeIcon>
        </button>
        <div className="cm-wrap">
          <img src="/icons/stimulus-help.svg" alt="Stimulus Help" width="500px" height="500px" />
          <div className="content">
            <h3 className="title">
              Due to extraordinarily high demand, we are only able to service a limited
              number of businesses.
            </h3>
            <p>
              As a result, there is a nominal charge of $29 to reserve your appointment
              with a Specialist.
            </p>
          </div>
        </div>
        <button
          type="button"
          className="btn btn-primary btn-lg"
          onClick={continueToCheckout}
        >
          Continue
        </button>
      </div>
    </Modal>
  );
};

export default ConsultationFeeModalComponent;
