/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import axios from 'axios';
import Header from '@/components/Header/Header';
import SEOHead from '@/components/SEOHead/SEOHead';
import HomeHero from '@/components/HomeHero/HomeHero';
import TrustPilotShortStyles from '@/components/TrustPilot/TrustPilotShortStyles';
import HomepageStyles from '@/components/styles/HomepageStyles';
import { WordPressAPI } from '../config';
import validatePaidCampaign from '@helpers/validatePaidCampaign';
import ConsultationFeeModalComponent from '@/components/ConsultationFeeModal/ConsultationFeeModalComponent';

const PullUpScheduler = dynamic(
  () => import('@components/PullUpScheduler/PullUpSchedulerV2'),
  { ssr: false }
);
const HelpYouComponent = dynamic(
  () => import('@components/Homepage/HelpYouComponent'),
  { ssr: false }
);
const AboutServiceComponent = dynamic(
  () => import('@components/AboutService/AboutServiceComponent'),
  { ssr: false }
);
const FooterComponent = dynamic(
  () => import('@components/Footer/FooterComponent'),
  { ssr: false }
);
const TaxTipsComponent = dynamic(
  () => import('@components/Homepage/TaxTipsComponent'),
  { ssr: false }
);
const PartnersWithComponent = dynamic(
  () => import('@components/Homepage/PartnersComponent'),
  { ssr: false }
);
const TrustPilotComponent = dynamic(
  () => import('@components/TrustPilot/TrustPilotComponent'),
  { ssr: false }
);

const FooterNewsModal = dynamic(import('@/components/FooterNewsModal'));

const Index = ({ data: { acf }, blogModuleData }) => {
  const {
    about_services,
    background_models,
    bn_title,
    business_needs,
    business_needs_title,
    hero_background_image,
    hero_sub_title,
    hero_title,
    lead_values,
    meta_description,
    offered_product,
    og_image,
    page_title_tag,
    partners_with,
    section_subtitle,
    wwo_section_title,
    additional_questions,
    phone_number_setup,
  } = acf;

  const paid_campaign = validatePaidCampaign(acf.paid_campaign);
  const [isSchedulerOpen, setIsSchedulerOpen] = useState(false);
  const [hideSchedulerHeader, setHideSchedulerHeader] = useState(false);

  useEffect(() => {
    if (isSchedulerOpen) {
      setHideSchedulerHeader(true);
    } else {
      setHideSchedulerHeader(false);
    }
  }, [isSchedulerOpen])

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      return isSchedulerOpen
        ? document.body.classList.add('no-overflow')
        : document.body.classList.remove('no-overflow');
    }

    return () => {
      isMounted = false;
    };
  }, [isSchedulerOpen]);

  const openFormModal = () => {
    setIsSchedulerOpen(true);
  };

  return (
    <>
      <SEOHead>
        <title>{page_title_tag}</title>
        <meta name="description" content={meta_description} />
        <meta name="og:image" content={og_image.url} key="open-graph-image" />
        <script
          type="text/javascript"
          src="//app-ab33.marketo.com/js/forms2/js/forms2.min.js"
        />
      </SEOHead>
      <Header
        AdditionalQuestions={additional_questions}
        leadValues={lead_values}
        PaidCampaign={paid_campaign}
        phone_number={phone_number_setup}
        isHome={true}
        hideSchedulerHeader={hideSchedulerHeader}
      />
      <HomeHero
        data={{ hero_background_image, hero_title, hero_sub_title, background_models }}
        handleScheduler={openFormModal}
      />
      <TrustPilotShortStyles>
        <div className="tp-container">
          <TrustPilotComponent templateID="5406e65db0d04a09e042d5fc" height="28px" alt />
        </div>
      </TrustPilotShortStyles>

      <HomepageStyles>
        <HelpYouComponent data={{ bn_title, business_needs }} />

        <section className="happy-to-help">
          <div className='happy-content'>
            <h2 className="title">{business_needs_title}</h2>
            {about_services.map((item, index) => (
              <AboutServiceComponent
                key={index}
                data={item}
                isHomePage={true}
                handleScheduler={openFormModal}
                whiteBg
                smallTitles
                showImgs
                customStyle="homepage"
                wordPressVideo={false}

              />
            ))}
          </div>
        </section>

        <section className="desktop-tp-widget d-md-block py-0">
          <div className="container">
            <TrustPilotComponent
              templateID="54ad5defc6454f065c28af8b"
              height="240px"
              alt
            />
          </div>
        </section>

        <div className="bg-wrapper">
          {/* <PartnersWithComponent data={{ partners_with }} /> */}

          <TaxTipsComponent
            data={{ wwo_section_title, section_subtitle, blogModuleData, openFormModal }}
          />
        </div>
      </HomepageStyles>

      {/* Email Newsletter */}
      <FooterNewsModal />

      <FooterComponent phone_number={phone_number_setup} isHome={true} />

      {isSchedulerOpen && (
        <PullUpScheduler
          setIsSchedulerOpen={setIsSchedulerOpen}
          AdditionalQuestions={additional_questions}
          leadValues={lead_values}
        />
      )}
    </>
  );
};

export const getStaticProps = async () => {
  const GET_POST_URL = `${process.env.NEXT_PUBLIC_BLOG}/wp-json/wp/v2/posts`;

  const axiosWP = axios.get(`${WordPressAPI.pages}/13199`);
  const axiosBlog1 = axios.get(`${GET_POST_URL}?slug=quarterly-estimated-taxes&_embed=true`);
  const axiosBlog2 = axios.get(`${GET_POST_URL}?slug=how-to-find-a-virtual-bookkeeper&_embed=true`);
  const axiosBlog3 = axios.get(`${GET_POST_URL}?slug=entity-right-business&_embed=true`);

  const [{ data }, blogData1, blogData2, blogData3] = await Promise.all([axiosWP, axiosBlog1, axiosBlog2, axiosBlog3]);

  const post1 = blogData1.data[0];
  const post2 = blogData2.data[0];
  const post3 = blogData3.data[0];

  const blogModuleData = [post1, post2, post3];

  return {
    props: {
      data,
      blogModuleData
    },
    // revalidate: 1,
  };
};

export default Index;
