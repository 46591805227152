/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg'
import sanitize from 'sanitize-html';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { HomeHeroStyles } from './HomeHeroStyles';
import dynamic from 'next/dynamic';

const ImageSwitch = dynamic(
  () => import('./ImageSwitch'),
  { ssr: false }
);

const sanitizeSettings = {
  allowedTags: ['p', 'h2', 'h3', 'br', 'strong', 'b'],
};

const HomeHero = ({ data, handleScheduler }) => {
  const {
    hero_background_image,
    hero_title,
    hero_sub_title,
    background_models = false,
    bottom_text,
    hero_image = false,
  } = data;
  const [model, setModel] = useState(background_models ? background_models[0] : []);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0.4,
    height: 0.4,
  });

  function handleResize() {
    if (window.innerWidth < 992) {
      setImageDimensions({
        width: 0.4,
        height: 0.4,
      });
    } else {
      setImageDimensions({
        width: 0.5,
        height: 0.5,
      });
    }
  }

  useEffect(() => {

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // rotate hero model every 8 seconds
  useEffect(() => {
    if (background_models) {
      let currentIndex = 0;
      const rotateModel = () => {
        if (currentIndex === background_models.length) currentIndex = 0;
        setModel(background_models[currentIndex]);
        currentIndex += 1;
      };

      rotateModel();

      const timerID = setInterval(rotateModel, 8000);

      return () => clearInterval(timerID);
    }
  }, []);



  return (
    <HomeHeroStyles background={hero_background_image?.url}>
      <div className="hero-wrap">
        <main className="content">
          <h1 className="title">{hero_title}</h1>
          <div
            className="sub-header"
            dangerouslySetInnerHTML={{
              __html: sanitize(hero_sub_title, sanitizeSettings),
            }}
          />
          <div className={`btn-wrap ${bottom_text ? 'mb-lg-5' : ''}`}>
            <button className="btn btn-hero-cta" type="button" onClick={handleScheduler}>
              Schedule My Free Call
              <ReactSVG className="btn-icon" src="/icons/calendar-check.svg" />
            </button>
          </div>
          {
            bottom_text && (
              <div className="bottom-text" dangerouslySetInnerHTML={{ __html: bottom_text }} />
            )
          }
        </main>

        <div className="customers">
          {hero_image ?
            (
              <img src={hero_image} alt="Hero image" width="500px" height="500px" />
            ) : (
              <SwitchTransition mode="out-in">
                <CSSTransition key={model.model_name} classNames="fade" timeout={500}>
                  <ImageSwitch model={model} imageDimensions={imageDimensions} />
                </CSSTransition>
              </SwitchTransition>
            )}
        </div>
      </div>
    </HomeHeroStyles>
  );
};

export async function getStaticProps() {
  const pagesRes = await fetch(`${WordPressAPI.pages}/12422`);
  const data = await pagesRes.json();

  return {
    props: {
      data: data.acf,
    },
    revalidate: 1, // In seconds
  };
}

export default HomeHero;
