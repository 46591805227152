import styled from 'styled-components';

const TPStyles = styled.div`
  background-color: #F8F6F4;
  padding: 20px 0;
  position: relative;

  .tp-container {
    margin: 0 auto;
    max-width: 100%;
  }
`;

export default TPStyles;
