import styled from 'styled-components';

const HomepageStyles = styled.div`
  .fixed-cta-wrapper {
    .btn-mobile-banner {
      border-radius: 0;
      bottom: 0;
      position: fixed;
      z-index: 1;
    }
  }

  .help-you {
    background: rgb(255, 255, 255);

    ${props => props.theme.media.md} {
      background: white;
    }

    h2 {
      font-size: 40px;
      font-weight: 500;
      text-align: center;
    }

    .container {
      max-width: 1400px;
    }

    .need {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-bottom: 2rem;
      text-align: center;

      &.last {
        margin-bottom: 0;
      }

      ${props => props.theme.media.md} {
        margin-bottom: 2.5rem;
        
      }

      .icon {
        align-items: center;
        background-color: #FE6600;
        border-radius: 50%;
        display: flex;
        height: 125px;
        justify-content: center;
        min-height: 125px;
        min-width: 125px;
        width: 125px;
      }
      
      .bordered-container {
        width: 100%;
        position: relative;
        padding-bottom: 25px;

        :before {
          content: '';
          position: absolute;
          top: 0;
          right: -15px;
          width: 1px;
          height: 100%;
          background: #e5e5e5;
        }
      }

      &.last .bordered-container :before {
        display: none;
      }

      .content {
        position: relative;
        text-align: center;
        width: 100%;
        max-width: 275px;
        margin: auto;

        h3 {
          font-size: 23px;
          color: #3F5261;
          font-weight: 500;
          margin: 0.5em 0;
          text-align: center;
        }

        p {
          font-size: 15px;
          margin-bottom: 0;
          max-width: 24em;
          text-align: left;

          ${props => props.theme.media.md} {
            margin-bottom: 1rem;
            max-width: none;
          }
        }

        a {
          display: inline-block;
          align-self: center;
          background: #60B9A9;
          border-radius: 2.5rem;
          color: #fff;
          margin-top: 1rem;
          padding: 0.25rem .75rem;
          text-decoration: none;
          text-align: center;
          font-weight: 300;
          font-size: 14px;

          :hover {
            background: #89D3C6;
          }

          ${props => props.theme.media.md} {
            align-self: flex-start;
            margin-top: 0rem;
            min-width: 13rem;
            text-align: center;
          }
        }
      }
    }

    .need:first-of-type svg {
      margin-right: 10px;
    }

  }

  .happy-to-help {
    padding: 15px 0;

    .happy-content {
      padding: 0;
      border-radius: 11px;
      color: #3F5261;

      .cta-btn {
        padding: 1rem 1.25rem;
        margin-bottom: 2rem;
      }

      h2 {
        font-size: 25px;
        font-weight: 500;
        text-align: center;
        color: #3F5261;
      }
  
      h3 {
        font-size: 22px !important;
        text-align: left;
        color: #3F5261;
        font-weight: 500;
      }

      p {
        font-size: 13px;
        text-align: left;
      }

      .video-wrapper.homepage {
        backgroung: #EEE7DB;
        margin-left: -20px;
        margin-right: -20px;
        margin-bottom: 3rem;
      }

      .image-wrapper.homepage {
        padding: 15px;
        padding-top: 0;
        margin-top: 0;
        margin-bottom: 15px;
      }

      .mb-3 {
        object-fit: contain;

        ${props => props.theme.media.lg} {
          height: 400px;
        }
      }
    }
    
    ${props => props.theme.media.md} {
      padding: 50px;

      .happy-content {
        background: #F8F6F4;
        padding: 50px;

        .video-wrapper.homepage {
          margin: auto;
        }

        p {
          font-size: 15px;
          line-height: 1.5;
        }

        h2  {
          font-size: 40px;
        }
        h3 {
          font-size: 1.8rem !important;
          max-width: 490px;
          width: 100%;
        }
      }
    }
  }

  .bg-wrapper {
    background: none;
  }

  .partners {
    h3 {
      font-size: 30px;
      font-weight: 500;
      text-align: center;
    }

    .partner-wrapper {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      place-items: center;
      margin-left: -75px;
      margin-right: -75px;

      > div > div {
        align-items: center;
        display: flex;
        justify-content: center;
      }

      svg {
        width: auto;
        transform: scale(1.2);

        path,
        polygon,
        rect {
          fill: rgba(0, 0, 0, 0.3) !important;
        }
      }

      > div:nth-of-type(4){
        svg {
          transform: scale(1.75);
        }
      }
    }
  }

  .tax-tips {
    h2 {
      font-size: 28px;
      font-weight: 500;
      margin-bottom: 0.5rem;
      text-align: center;
    }

    .section-subtitle {
      margin-bottom: 1rem;
      text-align: center;
      font-size: 13px;
      line-height: 1.3;
    }

    ${props => props.theme.media.md} {
      h2 {
        font-size: 40px;
      }
      .section-subtitle {
        margin-bottom: 4rem;
        font-size: 18px;
      }
    }

    .products-wrapper {
      .product {
        margin-bottom: .5rem;
        padding: 0;
        text-align: center;

        ${props => props.theme.media.md} {
          margin-bottom: 0;
          padding: 0 30px;
        }
       .img-wrapper > div{
         margin-left: auto;
         margin-right: auto;
       }
        .product-img {
          margin-bottom: 1.5rem;
          width: 100%;
          max-height: 167px;
        }

        .copy {
          padding: 0 15px;

          ${props => props.theme.media.md} {
            padding: 0;
          }

          .product-title {
            font-size: 20px;
            font-weight: 600;
            line-height: 1.3;
            margin: 1rem 0;
            color: #3F5261 !important;
          }

          .product-description {
            p {
              font-size: 13px;
              line-height: 1.2;
              margin: 0 auto;
              text-align: left;
            }
          }

          .text-center {
            color: #FE6600;
            display: block;
            text-align: center;
            margin: 1rem 0;
          }

          ${props => props.theme.media.md} {

            .product-title {
              font-size: 19px;
            }
            .product-description {
              p {
                font-size: 16px;
                line-height: 1.5;
              }
            }
  
          }
        }
      }
    }
  }
`;

export default HomepageStyles;
