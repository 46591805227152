import React from 'react';
import Head from 'next/head';
import sanitizeHtml from 'sanitize-html';

const SEOHead = (props) => {
  const children = props.children;
  const SEOPageName = "1-800Accountant";

  const validTitleHtmlEntities = {
    '&amp;': '&'
  };

  const appendSEOPageName = (title) =>
    title.includes(SEOPageName)
      ? title
      : `${title} | ${SEOPageName}`;

  const sanitize_title = (title) => {
    const re = new RegExp(Object.keys(validTitleHtmlEntities).join("|"),"gi");
    const sanitizeString = appendSEOPageName(sanitizeHtml(title, { allowedTags: [] }));
    return sanitizeString.replace(re, matched => validTitleHtmlEntities[matched]);
  };

  return (
    <Head >
      {React.Children.map(children, (child, i) => {
        //Adds the SEOPageName to the end of all titles.
        if (child
          && 'title' === child.type
          && "string" === typeof (child.props.children)) {
          const newTitle = React.cloneElement(child, {
            children: sanitize_title(child.props.children),
          });
          return newTitle;
        }
        //Decodes HTML entities in og:title and descriptions
        if (child &&
          ('og:title' === child.props?.property || 'og:description' === child.props?.property || 'description' === child.type
            || 'twitter:description' === child.props?.name || 'twitter:title' === child.props?.name) &&
          "string" === typeof (child.props.content)) {
          const decodedChild = React.cloneElement(child, {
            content: sanitizeHtml(child.props.content),
          });
          return decodedChild;
        }

        //Returns as usual if it's not title tag
        return child
      })}
    </Head>
  )

};

export default SEOHead;
